import React from 'react';
import {
    Tick, Card, Calendar, Dashboard, CreditCard, Cross, DirectionArrow, Flag, MainLogo,
    Handshake, HighCourt, Home, Notepad, ScotlandFlag, TickWhite } from './icons';

const Icons = {
    "calendar": (props) => <Calendar {...props} />,
    "card": (props) => <Card {...props} />,
    "creditcard": (props) => <CreditCard {...props} />,
    "cross": (props) => <Cross {...props} />,
    "dashboard": (props) => <Dashboard {...props} />,
    "directionarrow": (props) => <DirectionArrow {...props} />,
    "flag": (props) => <Flag {...props} />,
    "mainlogo": (props) => <MainLogo {...props} />,
    "handshake": (props) => <Handshake {...props} />,
    "highcourt": (props) => <HighCourt {...props} />,
    "home": (props) => <Home {...props} />,
    "notepad": (props) => <Notepad {...props} />,
    "scotlandflag": (props) => <ScotlandFlag {...props} />,
    "tick": (props) => <Tick {...props} />,
    "tickwhite": (props) => <TickWhite {...props} />,
    "default": () => null
};

const Icon = (props) => (Icons[props.name] || Icons.default)(props);

export default Icon;