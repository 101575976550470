import React from 'react';
import { navigate } from "gatsby-link";
import styled from 'styled-components';
import Icon from '../components/svg';
import ButtonLink from '../components/Buttons/ButtonLink';
import BackLink from '../components/Buttons/BackLink';
import SiteFooter from '../components/Report/SiteFooter';
import { useJourneyContext } from "../components/Contexts/JourneyContext/JourneyContext";

function SolutionStory({ pageContext }) {
  let story = pageContext.story;
  const journeyContext = useJourneyContext();

  return (
    <>
     <Panel className="bg-grey" image={story.imagex3}>
        <div className="container">
        <StyledBackLink tabIndex="0" className="ml-0 mb-4" onClick={() => navigate(-1)} />
          </div>
          <img src={`../../../${story.imagex2}.png`} alt="" className="d-md-none w-100" />
          <div className="container pb-4">
          <div className=" col-12 col-md-5">
            <h4 className="pt-5 pt-md-4 mb-4">" {story.review} "</h4>
            <p>{story.bio}</p>
          </div>
        </div>
     </Panel>

     
     <div className="container py-5">
   
         <H2 className="font-weight-bold pb-4">Read {story.displayName}'s story</H2>
         <UL className="pl-3 mb-0 row">
            {story?.storyPoints?.map(point => (
              <li key={`${story.name}-con-${point.order}`} className="d-flex col-12 col-md-6">
                <div className="list-wrapper w-100 pt-md-2 mb-3 pb-md-4 d-flex"><Icon className="pt-1" name="tick"  /><span className="ml-2" >{point.value}</span></div>
              </li>
            ))}
         </UL>

      <div className="py-4 pt-md-5 mt-3">
              <H2 className="font-weight-bold mb-4 pb-md-3">How {pageContext.solution.abbreviation.charAt(0)==="I" ? "an" : "a"} {pageContext.solution.abbreviation} could help</H2>
              <Table className="table">
                <tbody>
                  <tr>
                    <td>{story.productTypeTitle}</td>
                    <td className="text-right">{pageContext.solution.abbreviation}</td>
                  </tr>
                  <tr>
                    <td>{story.debtTitle}</td>
                    <td className="text-right">{story.debt}</td>
                  </tr>
                  <tr>
                    <td>{story.oldRepaymentTitle}</td>
                    <td className="text-right">{story.oldRepayment}</td>
                  </tr>
                  <tr>
                    <td>{story.newRepaymentTitle}</td>
                    <td className="text-right">{story.newRepayment}</td>
                  </tr>
                  <tr>
                    <td>{story.monthlyReductionTitle}</td>
                    <td className="text-right">{story.monthlyReduction}</td>
                  </tr>
                  {story?.debtWrittenOff ?
                  <tr>
                    <td>{story.debtWrittenOffTitle}</td>
                    <td className="text-right">{story.debtWrittenOff}</td>
                  </tr>
                  : null}
                </tbody>
              </Table>
              <p className="py-4 text-center col-md-10 mx-auto">{story.storyInfo?.replace(/{solutionName}/g, pageContext.solution.abbreviation)}</p>
      </div>

      <div className="container pb-5 pt-2 text-center">
            <h4 className="font-weight-bold px-3 px-md-0">{pageContext.solution.contactUsTitle}</h4>
              <ButtonLink
                onClick={() => window.open('tel:' + journeyContext?.source?.phoneNo)}
                className="btn d-block mx-auto mt-4"
              >
                Get Started
              </ButtonLink>
        </div>
     </div>
     <SiteFooter footerTandC={pageContext.solution.footerTandC} />
    </>
  );
}
const UL = styled.ul`
  li{
    font-size:18px;
  }
  @media (min-width: 768px) {
  li:nth-child(1) .list-wrapper, li:nth-child(2) .list-wrapper{
    border-bottom:1px solid ${props => props.theme.colors.grey1};
  }
}
`;

const StyledBackLink = styled(BackLink)`
  margin: 10px 0px 0px 0px;
  margin-top: 20px;
  @media (min-width: 767px) {
    margin: 10px 0px 0px 15px;  
  }
`;

const H2 = styled.h2`
  font-size:1.5rem;
  @media (min-width: 768px) {
    font-size:2rem;
  }
`;

const Panel = styled.div`
  position: relative;
  background-color:  ${props => props.theme.colors.grey2};


  @media (min-width: 768px) {
    min-height: 378px;
    &::before {
      display: block; 
      content: "";
      width: 50%;
      position:absolute;
      height: 100%;
      right: 0;
      top: 0;
      background-image: url(../../../${props => props.image}.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% auto;
    }
  }
  p {
    font-sizee:18px;
  }
`;



const Table = styled.table`
border-collapse: separate;
border-spacing: 0px 10px;

  td{
    padding:20px 10px 20px;
    border-top:none;
    font-size:1rem;
    background-color: ${props => props.theme.colors.grey2};
  }
  tr:last-child td{
    background-color: ${props => props.theme.colors.green};
    color: ${props => props.theme.colors.white};
  }
 
  @media (min-width: 768px) {
    border-spacing: 20px 8px;
    margin-left:-16px;
    td {
      font-weight:500;
      width:50%;
      font-size:1.1rem;
      padding:20px;
    }
  }
`;

export default SolutionStory;