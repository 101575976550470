import React from 'react';
import styled from 'styled-components';
import {BackIcon} from '../svg/icons'

const BackLink = (props) => (
    <BackLinkStyled href="#" {...props}>
        <BackIcon/>
    &nbsp;Back</BackLinkStyled>
);

const BackLinkStyled = styled.a`
    text-decoration: none;
    font-size: 16px;
    align-items:center;
    color: ${props => props.theme.colors.black};
    display: inline-flex;
    cursor: pointer;
`;

export default BackLink;